/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/

// Grid specific:
$grid: 12;
$gutter: 40px;
$gutter_sm: 40px;
$gutter_md: 40px;
$gutter_lg: 40px;
$gutter_xlg: 40px;
$gutter_xxlg: 40px;


// Grid & Layout breakpoints:
$container-max-width: 1280px;

$xs: 479px;
$sm: 480px;
$md: 768px;
$lg: 980px;
$xlg: 1200px;
$xxlg: 1366px;
$xxxlg: 1900px;


// Širina ekrana na kojoj se preslaguju column_content i column_sidebar
$column_break: 980px;




/**************************************************
    FONTS
    **********************************************/

$font_prim: 'Muli', sans-seif;


$base-fontSize: 62.5%;
$base-lineHeight: 1.5;


// Font weights
$thin: 100;
$extra_light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;
$heavy: 900;

/**************************************************
    COLORS
    **********************************************/

// base colors
$color_01: #6d6e71;
$color_02: #eaeaea;
$color_03: #e8e115;
$color_04: #b3e6ce;



//Social netwroks colors
$color_facebook: #3b5998;
$color_twitter: #00b6f1;
$color_youtube: #b31217;
$color_instagram: #c32aa3;
$color_instagram: #c32aa3;
$color_pinterest: #cb2027;
$color_googleplus: #df4a32;
$color_googleplus: #df4a32;
$color_linkedin: #007bb6;
$color_vimeo: #45bbff;